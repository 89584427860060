const region: string = document
  .getElementsByTagName("page-settings")[0]
  .getAttribute("data-region");
function getLocaleCurrency(currency: number): string {
  currency = Math.round(currency);
  let formatter: Intl.NumberFormat;
  if (region == "Eng") {
    formatter = new Intl.NumberFormat("en-GB", {
      currency: "GBP",
      minimumFractionDigits: 0,
      style: "currency"
    });
  } else {
    formatter = new Intl.NumberFormat("en-US", {
      currency: "USD",
      minimumFractionDigits: 0,
      style: "currency"
    });
  }
  return formatter.format(currency);
}

function getLocaleSurgaUrl(): string {
  if (region == "Eng") {
    return "https://www.surgacentral.co.uk/";
  } else {
    return "https://www.surgacentral.com/";
  }
}
function getLocaleForLease(): string {
  if (region == "Eng") {
    return "To Let";
  } else {
    return "For Lease";
  }
}
function getLocaleForLeaseShort(): string {
  if (region == "Eng") {
    return "Let";
  } else {
    return "Lease";
  }
}
function getLocaleArea(land: number): string {
  if (region == "Eng") {
    return land.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " sq. ft.";
  } else {
    return land.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "m²";
  }
}
function getSuburbForSearch(suburb: string): string {
  if (region == "Eng") {
    let newSuburb: string[] = suburb.split(" ");
    return newSuburb[0];
  } else {
    return suburb;
  }
}
function getPAorPAX(): string {
  if (region == "Eng") {
    return "PAX";
  } else {
    return "PA";
  }
}
function getBldArea(): string {
  if (region == "Eng") {
    return "sq. ft.";
  } else {
    return "m<sup>2</sup>";
  }
}
function getLocaleForLeased(): string {
  if (region == "Eng") {
    return "Let";
  } else {
    return "Leased";
  }
}
function getLocaleCountryCode(): string {
  if (region == "Eng") {
    return "uk";
  } else {
    return "au";
  }
}
function getCountryCode(): string {
  if (region == "Eng") {
    return "GB";
  } else {
    return "AU";
  }
}
export { getLocaleForLeaseShort, getLocaleCurrency, getLocaleArea, getLocaleForLease, getSuburbForSearch, getPAorPAX, getBldArea, getLocaleForLeased, getCountryCode, getLocaleCountryCode, getLocaleSurgaUrl };
