export function setupGrid() {
    var gridSections = document.getElementsByName("grid-row-component");
    for (var i = 0; i < gridSections.length; i++){
        // load property values
        var isIndent = setNumberOrDefault(gridSections[i].dataset.indent, 0);
        var topMargin = setNumberOrDefault(gridSections[i].dataset.topmargin, 0);
        var bottomMargin = setNumberOrDefault(gridSections[i].dataset.bottommargin, 0);
        var space = setNumberOrDefault(gridSections[i].dataset.spacebetween, 0);
        // load section components
        var sectionRows = gridSections[i].getElementsByTagName("section-row");
        var sectionColumns = gridSections[i].getElementsByTagName("section-column");
        setGridStyles(gridSections[i], isIndent, topMargin, bottomMargin);
        if (gridSections.length > 1) {
            setRowAndColumnStyle(sectionRows, sectionColumns, space);
        }
    }
}

function setGridStyles(gridSection, isIndent, topMargin, bottomMargin) {
    if (isIndent == 1) {
        gridSection.classList.add("container");
    }
    gridSection.style.paddingTop = topMargin + 'px';
    gridSection.style.paddingBottom = bottomMargin + 'px';
}

function setRowAndColumnStyle(rows, columns, space) {
    var i = 0;
    for (i = 0; i < rows.length; i++) {
        rows[i].style.marginRight = "-"+space/2 + "px";
        rows[i].style.marginLeft = "-"+space/2 + "px";
    }
    for (i = 0; i < columns.length; i++) {
        columns[i].style.paddingRight = space/2 + "px";
        columns[i].style.paddingLeft = space/2 + "px";
    }
}

function setNumberOrDefault(value, defaultValue) {
    var integer = parseInt(value, 10);
    return setValueOrDefault(integer, defaultValue);
}

function setValueOrDefault(value, defaultValue) {
    if (!value) {
        return defaultValue;
    }
    return value;
}