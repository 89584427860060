// @ts-ignore
import { assetFileManager } from "./assetFileHelperComp";
import { globalValues } from "./globalValuesComp";
import { detectBrackets, manageLandRange, manageListingTypeString, managePriceRange } from "./listingCommonComp";
import {
  getBldArea
} from "./localeComp";
import { PropertyType } from "./models/assetFileModel";
import { SurgaMapProperty } from "./models/surgaapi/surgaMapPropertyModel";

// Listing functions
function mapListingFunctions(
  property: SurgaMapProperty,
  surgaListingType: string
) {
  // generates property text from keywords.
  function generateText(line: string) {
    let operationComplete: boolean = false;
    let generatedText: string = line;
    let isEmpty: boolean = true;
    do {
      const keystring: string = detectBrackets(generatedText);
      if (keystring.length === 0) {
        operationComplete = true;
      } else {
        const replacementText: string = keywordReplacement(
          keystring.slice(2, keystring.length - 2),
          surgaListingType
        );
        if (replacementText !== "") {
          isEmpty = false;
        } else {
          let index: number = generatedText.indexOf(keystring);
          index = index + keystring.length;
          var nextChar: string = generatedText[index];
          while (nextChar === " " || nextChar === ",") {
            generatedText =
              generatedText.slice(0, index) +
              generatedText.slice(index + 1, generatedText.length);
            nextChar = generatedText[index];
          }
        }
        generatedText = generatedText.replace(keystring, replacementText);
      }
    } while (!operationComplete);
    if (isEmpty) {
      return "<h3 style='opacity: 0'>.</h3>";
    }
    return generatedText.trim();
  }

  function keywordReplacement(keyword: string, listingType: string): string {
    let resultVal: string = returnStringResult(keyword, listingType);
    if (resultVal == null) {
      console.log(
        "Listing property keyword not found. Please check setup. KEYWORD: '" +
        keyword +
        "'"
      );
      resultVal = "";
    }
    return resultVal;
  }

  // Retrieves listing details from string.
  function returnStringResult(
    propertyName: string,
    listingType: string
  ): string {
    let result: string = "";
    const lowerCaseName: string = propertyName.toLowerCase().trim();
    switch (lowerCaseName) {
      case "title": {
        result = property.Title;
        break;
      }
      case "sitename": {
        result = property.SiteName;
        break;
      }
      case "streetnumber": {
        result = property.StreetNumber;
        break;
      }
      case "streetname": {
        result = property.StreetName;
        break;
      }
      case "suburb": {
        result = property.SuburbName;
        break;
      }
      case "postcode": {
        result = property.PostCode;
        break;
      }
      case "propertytype": {
        try {
          result = getPropertyType(property.PropertyType.split("|")[0]);
        } catch(ex) {};
        break;
      }
      case "area": {
        result = manageArea(listingType);
        break;
      }
      case "price": {
        result = managePrice(listingType);
        break;
      }
      case "areapipeprice": {
        result = manageAreaPrice(listingType);
        break;
      }
      case "listingtype": {
        result = manageListingTypeString(listingType);
        break;
      }
    }
    return result;
  }

  function manageAreaPrice(listingType: string): string {
    let area: string = manageArea(listingType);
    let price: string = managePrice(listingType);
    if (area !== "" && price !== "") {
      return area + " | " + price;
    }
    if (area !== "") {
      return area;
    }
    return price;
  }
  function managePrice(listingType: string) {
    if (listingType.toLowerCase() === "v" || listingType.toLowerCase() === "i") {
      const salePriceString: string = manageSaleRange();
      if (salePriceString !== "") {
        return salePriceString;
      }
    } else {
      const rentPriceString: string = manageRentRange();
      if (rentPriceString !== "") {
        return rentPriceString;
      }
    }
    return "";
  }
  function manageSaleRange(): string {
    // determine if show in place if rent.
    const showInPlace: string = property.ShowInPlaceofPrice;
    if (showInPlace && showInPlace !== "") {
      return showInPlace;
    }
    // determine if rent is to be displayed
    const displayPrice: boolean = property.DisplayPrice;
    if (displayPrice !== null && displayPrice !== undefined && !displayPrice) {
      return "";
    }
    // get price value
    const priceMin: number = property.PriceMin;
    const priceMax: number = property.PriceMax;
    return managePriceRange(priceMin, priceMax);
  }

  function manageRentRange(): string {
    // determine if show in place if rent.
    const showInPlace: string = property.ShowInPlaceofRent;
    if (showInPlace && showInPlace !== "") {
      return showInPlace;
    }
    // determine if rent is to be displayed
    const displayRent: boolean = property.DisplayRent;
    if (displayRent !== null && displayRent !== undefined && !displayRent) {
      return "";
    }
    // get price value
    const priceMin: number = property.RentMin;
    const priceMax: number = property.RentMax;
    return managePriceRange(priceMin, priceMax, true);
  }

  function manageArea(listingType: string): string {
    let propertyType: string = property.PropertyType;
    if ((propertyType.includes("L") || propertyType.includes("D")) && property.LandAreaConverted != 0) {
      return manageLandArea();
    } else {
      return manageBuildArea(listingType);
    }
  }

  function manageLandArea(): string {
    let landArea: number = property.LandAreaConverted;
    const landUOM = getAreaUOM(property.LandAreaUOM);
    const landAreaString: string = landArea
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return landAreaString + " " + landUOM;
  }

  function manageBuildArea(listingType: string): string {
    // get build area unit
    const bldUOM = getAreaUOM(property.BldAreaUOM);
    // return sale build area.
    if (listingType.toLowerCase() === "l") {
      const saleBldAreaString: string = manageBldArea(
        bldUOM,
        property.RentBldAreaMin,
        property.RentBldAreaMax
      );
      if (saleBldAreaString !== "") {
        return saleBldAreaString;
      }
    } else {
      const rentBldAreaString: string = manageBldArea(
        bldUOM,
        property.SaleBldAreaMin,
        property.SaleBldAreaMax
      );
      if (rentBldAreaString !== "") {
        return rentBldAreaString;
      }
    }
    return "";
  }

  function getAreaUOM(bldUOM: string): string {
    if (bldUOM === undefined || bldUOM === null) {
      bldUOM = "";
    }
    if (bldUOM === "sqm" || bldUOM === "m2") {
      bldUOM = getBldArea();
    }
    return bldUOM;
  }

  function getPropertyType(value: string): string {
    const propertyList: PropertyType[] = assetFileManager().get().propertyTypeWithAll();
    for (let property of propertyList) {
      if (property.code == value) {
        return property.name;
      }
    }
    return "";
  }

  function manageBldArea(
    bldUOM: string,
    bldMin: number,
    bldMax: number
  ): string {
    let bldString: string = manageLandRange(bldMin, bldMax, bldUOM);
    if (bldString !== "") {
      bldString += " " + bldUOM;
    }
    return bldString;
  }
  // convert ASP.net date to javascript date.
  function convertDate(date: string): number {
    const re = /-?\d+/;
    const m = re.exec(date);
    return parseInt(m[0], 10);
  }

  // gets the URL for use in all listing modules
  function getListingUrl(
    listingUrl: string,
    id: string,
    addAddress: boolean
  ): string {
    if (globalValues().get("isLive", "true") == "true") {
      let SEOAddress: string = "";
      if (addAddress) {
        SEOAddress = "/" + property.getAddressString();
      } else {
        SEOAddress = "/";
      }
      return listingUrl + SEOAddress + "/sid-" + id;
    } else {
      return listingUrl + "?webid=" + id;
    }
  }
  return { generateText, getListingUrl };
}
export { mapListingFunctions };