/**
 * Manages the search API and functions of the application.
 */
import { globalValues } from "./../../ts/globalValuesComp";
import { SearchOptions } from "./../models/searchOptionsModel";
import { SurgaProperty } from "./../models/surgaapi/surgaPropertyModel";
import { surgaSearchOptions } from "./searchSettingsStateComp";
import { searchHelperComp } from "./../searchHelperComp";
import { SearchResultModel } from "../models/search/searchResultModel";
import { SurgaMapProperty} from "../models/surgaapi/surgaMapPropertyModel";

const listingsRoute = "/api/surgaweb/getpropertylistings";
const mapRoute = "/api/surgaweb/getmaplistings";
const singleListingRoute = "/api/surgaweb/getpropertylisting";
const agencyId = document
  .getElementsByTagName("version")[0]
  .getAttribute("data-agency-id");

function surgaSearch() {
  async function searchFromUserSettings(searchNumber: number, index: number) {
    const searchOptionsState: SearchOptions = surgaSearchOptions()
      .get()
      .searchOptionsReactive().searchOptions;
    let data: SurgaProperty[] = [];
    // NEW SEARCH FUNCTION
    let searchResult: SearchResultModel = await searchHelperComp().search(
      searchOptionsState.searchText
    );
    data = await searchProperties(
      searchResult,
      searchOptionsState,
      searchNumber,
      index
    );
    return data;
  }

  async function searchProperties(
    searchResult: SearchResultModel,
    searchOptions: SearchOptions,
    searchNumber: number,
    index: number
  ): Promise<SurgaProperty[]> {
    let tmpPriceMax = searchOptions.filters.maxPrice;
    if (tmpPriceMax < 0) {
      tmpPriceMax = 1000000000;
    }
    let tmpAreaMax = searchOptions.filters.maxArea;
    if (tmpAreaMax < 0) {
      tmpAreaMax = 1000000000;
    }
    let isAscending = "DESC";
    if (searchOptions.isAscending) {
      isAscending = "ASC";
    }
    const sortColumnString = generateSortColumnString(searchOptions);
    let baseUrl = globalValues().get("searchUrl", "");
    let teamId = globalValues().get("teamId", "");
    if (baseUrl.endsWith("/")) {
      baseUrl = baseUrl.substr(0, baseUrl.length - 1);
    }
    let listingType = searchOptions.activeListingType;
    if (listingType === "V") {
      listingType = "VI";
    }
    let apiUrl: string =
      baseUrl +
      listingsRoute +
      "?agencyId=" +
      agencyId +
      "&minPrice=" +
      searchOptions.filters.minPrice +
      "&maxPrice=" +
      tmpPriceMax +
      "&minBldArea=" +
      searchOptions.filters.minArea +
      "&maxBldArea=" +
      tmpAreaMax +
      "&pageIndex=" +
      index +
      "&pageSize=" +
      searchNumber +
      "&listType=" +
      listingType +
      "&sortOrder=" +
      isAscending +
      "&sortColumns=" +
      sortColumnString +
      "&propertyType=" +
      searchOptions.filters.propertyType +
      "&street=" +
      encodeUtf8(searchResult.street)+
      "&suburb=" +
      encodeUtf8(searchResult.suburb)+
      "&sitename="+
      encodeUtf8(searchResult.siteName)+
      "&postcode="+
      encodeUtf8(searchResult.postcode)+
      "&state="+
      encodeUtf8(searchResult.state)+
      "&city="+
      encodeUtf8(searchResult.city)+
      "&searchText="+
      encodeUtf8(searchResult.searchText)+
      "&teamId="+
      teamId;
    let data: any[] = (await callSurgaApi(apiUrl)) as any[];
    if (data === null) {
      data = [];
    }
    let surgaPropertiesTmp: SurgaProperty[] = [];
    data.forEach((value) => {
      let surgaPropertyTmp: SurgaProperty = new SurgaProperty();
      surgaPropertyTmp.setupListing(value);
      surgaPropertiesTmp.push(surgaPropertyTmp);
    });
    return surgaPropertiesTmp;
  }

  async function searchMap(rteText: string): Promise<SurgaMapProperty[]> {
    let baseUrl = globalValues().get("searchUrl", "");
    let teamId = globalValues().get("teamId", "");
    if (baseUrl.endsWith("/")) {
      baseUrl = baseUrl.substr(0, baseUrl.length - 1);
    }
    let apiUrl: string =
      baseUrl +
      mapRoute +
      "?agencyId=" +
      agencyId +
      "&teamId="+
      teamId;
    let data: any[] = (await callSurgaApiNoData(apiUrl)) as any[];
    if (data === null) {
      data = [];
    }
    let surgaPropertiesTmp: SurgaMapProperty[] = [];
    data.forEach((value) => {
      let surgaPropertyTmp: SurgaMapProperty = new SurgaMapProperty(value, rteText);
      // check if address is displayed before pusing to list.
      if (surgaPropertyTmp.DisplayAddress) {
        surgaPropertiesTmp.push(surgaPropertyTmp);
      }
    });
    return surgaPropertiesTmp;
  }

  function encodeUtf8(input: string) {
    return input.replace("&", "%26");
  }

  function generateSortColumnString(searchOptions: SearchOptions) {
    switch (searchOptions.sortProperty) {
      case "date": {
        return "ListingCreateDate";
      }
      case "price": {
        if (searchOptions.activeListingType === "L") {
          return "RentMax";
        } else {
          return "PriceMax";
        }
      }
      case "area": {
        if (searchOptions.activeListingType === "L") {
          return "RentBldAreaMax";
        } else {
          return "SaleBldAreaMax";
        }
      }
      default: {
        return "";
      }
    }
  }

  async function searchSingleProperty(
    propertyId: string
  ): Promise<SurgaProperty> {
    let baseUrl = globalValues().get("searchUrl", "");
    if (baseUrl.endsWith("/")) {
      baseUrl = baseUrl.substr(0, baseUrl.length - 1);
    }
    const apiUrl: string =
      baseUrl +
      singleListingRoute +
      "?agencyId=" +
      agencyId +
      "&listingId=" +
      propertyId;
    const data = (await callSurgaApi(apiUrl)) as SurgaProperty;
    if (data === null) {
      window.location.href = globalValues().get("propertyNotFoundRedirect", "");;
    }
    return data;
  }

  async function callSurgaApi(route: string): Promise<object> {
    const json = await (await fetch(route)).json();
    const data = json.Data;
    return data;
  }
  async function callSurgaApiNoData(route: string): Promise<object> {
    const json = await (await fetch(route)).json();
    return json;
  }
  return {
    searchFromUserSettings,
    searchSingleProperty,
    searchMap
  };
}
export { surgaSearch };
